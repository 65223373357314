import React from "react";
import "./App.css";
import QuizList from "./QuizList";

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <h1>Quizzes</h1>
      </header> */}
      <QuizList />
    </div>
  );
}

export default App;
